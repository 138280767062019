/*! purgecss start ignore */
@tailwind base;

/**********************************************************************/
/***** Global *********************************************************/
/**********************************************************************/

html {
	scroll-behavior: smooth;
}

body {
	@apply bg-center;
	font-size: 1rem;
	line-height: 2rem;
	background-image: url(../../assets/bg-desktop.png);

	@screen sm {
		background-image: url(../../assets/bg-mobile.png);
	}
}

::selection {
	@apply bg-onyx-green text-white;
}

/**********************************************************************/
/***** Headers ********************************************************/
/**********************************************************************/

@responsive {
	.h1 {
		@apply font-header text-onyx-light-gray uppercase mb-6;
		font-size: 7rem;
		line-height: 7.5rem;

		@screen sm {
			font-size: 3.437rem;
			line-height: 4rem;
		}
	}

	.h2 {
		@apply text-onyx-light-gray font-header uppercase mb-6;
		font-size: 3.75rem;
		line-height: 4rem;

		@screen sm {
			font-size: 2.125rem;
			line-height: 2.5rem;
		}
	}

	.h3 {
		@apply text-onyx-light-gray font-header uppercase mb-6;
		font-size: 2rem;
		line-height: 3rem;

		@screen sm {
			font-size: 1.6rem;
			line-height: 1.875rem;
		}
	}

	.h4 {
		@apply text-onyx-light-gray font-header uppercase mb-6;
		font-size: 0.875rem;
		line-height: 1.25rem;
	}
}

h1 {
	@extend .h1;
}

h2 {
	@extend .h2;
}

h3 {
	@extend .h3;
}

h4 {
	@extend .h4;
}

/**********************************************************************/
/***** Body Text ******************************************************/
/**********************************************************************/

b, strong {
	@apply text-white;
}

p {
	@apply mb-6;
}

q {
	@apply block font-header mb-4;
	font-size: 3rem;
	line-height: 3.25rem;

	&:before, &:after{
		content:'';
	}
}

hr {
	@apply border-onyx-mid-gray w-full;
	@extend .mb-section;
}

/**********************************************************************/
/***** UI Elements ****************************************************/
/**********************************************************************/

a {
	@apply transition-colors duration-300;
	text-decoration-color: theme('colors.onyx-green');

	&:hover {
		@apply text-onyx-green;
	}

	&.text-link {
		@apply border-b border-onyx-green;
	}
}

button {
	@apply font-header uppercase min-w-64 px-4 py-6 border text-onyx-light-gray border-onyx-light-gray transition duration-300;
	font-size: 1.5rem;
	line-height: 1.5rem;

	&.smaller {
		@apply py-4;
		font-size: 1.25rem;
		line-height: 1.25rem;
	}

	@screen sm {
		@apply px-4 py-4;
		font-size: 1rem;
		line-height: 1rem;
	}

	&.dull {
		@apply text-onyx-mid-gray border-onyx-mid-gray;
	}

	&.primary {
		@apply text-onyx-green border-onyx-green;
	}

	&:not(.unhover):hover, &:not(.unhover).hover, &:not(.unhover).active {
		@apply bg-onyx-light-gray text-onyx-dark;

		&.dull {
			@apply bg-onyx-mid-gray text-onyx-white;
		}

		&.primary {
			@apply bg-onyx-green text-onyx-white;
		}
	}
}

input {
	@apply outline-none bg-transparent border-b border-onyx-white px-3 pb-3 mb-1 transition duration-300;

	&::placeholder {
		@apply text-onyx-lightish-gray;
	}

	&:focus, &:active, &.active {
		@apply border-onyx-green;
	}

	&.invalid {
		@apply border-onyx-invalid-red;
	}
}

.select {
	@apply border-b border-onyx-white rounded-none bg-transparent text-onyx-light-gray px-3 pb-3 mb-1 select-none cursor-pointer transition duration-300;

	& > .select-placeholder {
		@apply text-onyx-lightish-gray;
	}

	&.chosen {
		@apply text-onyx-dark-gray;
	}

	&:focus, &:active, &.active {
		@apply border-onyx-green outline-none;
	}
}

.tag {
	@apply inline-block border border-onyx-green bg-onyx-green-10 text-onyx-green p-1 transition duration-300 text-center;
	line-height: 1.5;

	&:hover, &.hover, &.active {
		@apply bg-onyx-green-80 text-onyx-white;
	}
}

textarea {
	@apply outline-none bg-transparent border-b border-onyx-white px-3 pb-3 mb-1 transition duration-300 resize-none;

	&::placeholder {
		@apply text-onyx-lightish-gray;
	}

	&:focus, &:active, &.active {
		@apply border-onyx-green;
	}

	&:invalid, &.invalid {
		@apply border-onyx-invalid-red;
	}
  &::-webkit-scrollbar-track {
    @apply bg-onyx-light-gray;
  }

  scrollbar-color: theme('colors.onyx-green') theme('colors.onyx-light-gray');
  scrollbar-width: thin;
  &::-webkit-scrollbar{
    width: 2px;
    @apply bg-onyx-light-gray;
  }

  &::-webkit-scrollbar-thumb{
    border-radius: 100px;
    @apply bg-onyx-green;
  }
}

.main {
	@apply flex flex-wrap justify-center relative overflow-hidden;

	.content {
		@apply w-full max-w-6xl;

		&:not(.content-width-only) {
			@apply flex justify-center items-center flex-col;
		}

		@screen sm {
			@apply px-8;
		}

		@screen lg {
			@apply px-16;
		}
	}
}

.content {
	ul {
		list-style: disc;
		padding-left: 1rem;
	}

	.hs-form ul {
		list-style: none;
		padding: 0;
	}
}

.haa-badge {
	max-width: 40%;

	@screen sm {
		&.smaller {
			max-width: 30%;
		}
	}

	@screen lg {
		max-width: unset;
		max-height: 6rem;
	}
}

@responsive {
	.mb-section {
		@screen sm {
			@apply mb-16;
		}

		@screen lg {
			@apply mb-32;
		}
	}
}

/**********************************************************************/
/***** Background gradient ********************************************/
/**********************************************************************/

@responsive {
	.bg-gradient-black-l {
		background: linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
	}

	.bg-gradient-black-r {
		background: linear-gradient(-90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
	}
}

.opacity-fade-b-50 {
	-webkit-mask-image: -webkit-linear-gradient(rgba(0,0,0,1) 50%, rgba(0,0,0,0));
}

@responsive {
	.opacity-fade-sides {
		-webkit-mask-image: -webkit-linear-gradient(left, rgba(0,0,0,0), rgba(0,0,0,1) 5%, rgba(0,0,0,1) 95%, rgba(0,0,0,0));
	}
}


/**********************************************************************/
/***** Aspect Ratio ***************************************************/
/**********************************************************************/

@responsive {
	.ratio-16\/9::before {
		@apply block;
		content: '';
		padding-bottom: 56.25%;
	}

	.ratio-21\/9::before {
		@apply block;
		content: '';
		padding-bottom: 42.85%;
	}

	.ratio-1\/1::before {
		@apply block;
		content: '';
		padding-bottom: 100%;
	}

	.ratio-2\/3::before {
		@apply block;
		content: '';
		padding-bottom: 150%;
	}

	.ratio-4\/3::before {
		@apply block;
		content: '';
		padding-bottom: 75%;
	}
}

/**********************************************************************/
/***** Slick **********************************************************/
/**********************************************************************/

.slick-track {
	@apply flex items-center content-center;

	.slick-slider.slick-align-top & {
		@apply items-start content-start;
	}
}

.slick-gap .slick-list {
	@apply -mx-4;
}
.slick-gap .slick-slide > div {
	@apply mx-4;
}

.slick-dots {
	@apply justify-center;
	display: flex !important;

	button {
		@apply bg-onyx-mid-gray mx-3 min-w-0 p-0;
		border: 0;
		width: 8px;
		height: 8px;
		border-radius: 4px;
		font-size: 0;

		&:focus {
			@apply outline-none;
		}
	}

	.slick-active button {
		@apply bg-onyx-green;
	}
}

/**********************************************************************/
/***** Animations *****************************************************/
/**********************************************************************/

.clip-r {
	clip-path: inset(0 100% 0 0);

	&:hover, &.unclip {
		clip-path: inset(0 0 0 0);
	}
}

.fill-effect {
	@apply text-onyx-black transition duration-300 mb-6;
	text-shadow:
		-1px -1px 0 theme('colors.onyx-light-gray'),
		1px -1px 0 theme('colors.onyx-light-gray'),
		-1px 1px 0 theme('colors.onyx-light-gray'),
		1px 1px 0 theme('colors.onyx-light-gray');

	@supports (-webkit-text-stroke: 1px theme('colors.onyx-light-gray'))
	or (text-stroke: 1px theme('colors.onyx-light-gray')) {
		@apply text-transparent;
		text-shadow: none;
		-webkit-text-stroke: 1px theme('colors.onyx-light-gray');
		text-stroke: 1px theme('colors.onyx-light-gray');
	}

	&:hover, &.hover, &.active {
		@apply text-onyx-light-gray;
	}
}


@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(10px);
  }
}

.animation-bounce {
	animation-name: bounce;
	animation-direction: alternate;
}

.animation-duration-2 {
	animation-duration: 2s;
}

.infinite {
	animation-iteration-count: infinite;
}

.animation-ease-in-out {
	animation-timing-function: ease-in-out;
}

.yoyo {
	animation-direction: alternate;
}

@responsive {
	.w-83 {
		width: 20.75rem;
	}
	.max-w-83 {
		max-width: 20.75rem;
	}
}

/**********************************************************************/
/***** HubSpot ********************************************************/
/**********************************************************************/

.hbspt-form {
	form {
		@apply w-full flex flex-col items-stretch;

		select.hs-input {
			@apply hidden;
		}

		input {
			@apply w-full;
		}

		textarea {
			@apply w-full;
		}

		/* select {
			@apply appearance-none;
			@extend .select;
		} */

		.input {
			@apply mb-16;
		}
	}

	label:not(.hs-error-msg) {
		@apply hidden;
	}

	label.hs-error-msg {
		@apply text-onyx-invalid-red;
	}

	ul.hs-error-msgs {
		@apply -mt-16 mb-8;
	}

	.submitted-message {
		@apply text-center;
	}
}

/*! purgecss end ignore */
@tailwind utilities;
.marTechAnchor {
	border: 2px solid #7FB44B;
    padding: 20px 30px;
    color: #fff;
    font-size: 20px;
}